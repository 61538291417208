* {
  font-family: 'Ubuntu', sans-serif;
}

a {
  text-decoration: none;
}

button,  input, input:focus, a, a:focus {
  border: 0;
  outline: 0;
  padding: 0;
}

.btn-start {
  margin-left: 20px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: get_col(btn-start-bg);
  color: get_col(text-light);
  text-transform: uppercase;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: bold;
  transition-duration: 0.2s;
  &:hover {
    background-color: get_col(btn-start-bg-hov);
  }
}

.btn-confirm {
  padding: 10px 30px;
  border-radius: 10px;
  background-color: get_col(btn-conf-bg);
  color: white;
  text-transform: uppercase;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: bold;
  transition-duration: 0.2s;
  &:hover {
    background-color: get_col(btn-conf-bg-hov);
  }
  &[disabled]:hover {
    background-color: get_col(btn-conf-bg-dis);
  }
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid get_col(hr1);
  border-bottom: 1px solid get_col(hr2);
}

