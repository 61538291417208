.statistics {
  margin: 30px 20px 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  span {
    padding-left: 20px;
  }
}

.input-field {
  margin: 20px 0;
  background-color: get_col(section-bg);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 3px solid transparent;
  @media (max-width: 768px) {
    margin: 20px 10px;
  }
  p {
    padding: 0 10px;
    text-align: center;
    font-size: 20px;
    white-space: pre-wrap;
  }
  .written {
    color: get_col(text-grey);
  }
  .left {
    color: get_col(text-dark);
  }
  input {
    width: 1px;
  }
}

.keyboard {
  padding: 10px;
  border: 1px solid get_col(key-bg); //#13629a;
  border-radius: 10px;
  background-color: get_col(keyb-bg); //#30abff;
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 50px;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    border: 4px solid transparent;
    background-color: get_col(key-bg);
    color: get_col(text-light);
    white-space: pre;
    margin: 5px;
    border-radius: 5px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.results {
  .float-img {
    height: 150px;
  }
  div {
    min-width: 200px;
  }
}
