.navbar {
  overflow: hidden;
  height: 80px;
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: -webkit-linear-gradient(top, get_col(header-bg1), get_col(header-bg2));
  border-radius: 10px;
  @media (max-width: 768px) {
    margin: 0 0 20px 0;
    border-radius: 0;
  }
  a {
    margin: 0;
    padding: 15px;
    border-radius: 10px;
    color: get_col(text-light);
    font-weight: bold;
    transition-duration: 0.2s;
    @media (max-width: 768px) {
      padding: 5px;
    }
    &:hover {
      background-color: get_col(header-hov);
    }
  }
  img {
    height: 60px;
    @media (max-width: 768px) {
      height: 50px;
    }
  }
  svg {
    height: 32px;
    width: auto;
    color: get_col(icon);
    @media (max-width: 768px) {
      height: 28px;
    }
  }
}
.current {
  background-color: get_col(header-hov);
}
