.main-container {
  margin: 20px;
  color: get_col(text-light);
  h2 {
    margin: 40px 20px 30px 20px;
  }
  @media (max-width: 768px) {
    margin: 20px 0;
  }
  .main-content {
    padding: 10px;
  }
}

.link {
  padding-left: 10px;
  padding-right: 10px;
  font-size: large;
  color: get_col(text-light);
  text-decoration: underline;
}

.warning {
  text-align: center;
  color: get_col(text-err);
  a {
    padding-left: 10px;
    padding-right: 10px;
    font-size: large;
    color: get_col(text-light);
    text-decoration: underline;
  }
}

.section {
  margin: 20px 0;
  padding: 30px;
  background-color: get_col(section-bg);
  border-radius: 10px;
  color: get_col(text-dark);
  .float-img {
    float: left;
    height: 240px;
    padding: 0 20px 20px 0;
  }
  .centred-img {
    display: block;
    width: 60%;
    margin: 0 auto;
  }
  h3:first-child, p:first-child {
    margin-top: 0;
  }
  @media (max-width: 768px) {
    margin: 20px 0;
    border-radius: 0;
  }
}

.chart-container {
  width: 100%;
  height: 300px;
}

.levels-gallery {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    box-sizing: border-box;
    width: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  img {
    height: 100px;
    width: auto;
  }
  span {
    padding-left: 10px;
    font-size: 14px;
  }
}
