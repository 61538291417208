.footer {
  height: 80px;
  margin: 20px 20px 0 20px;
  border-top: 1px solid get_col(text-light);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: get_col(text-light);
  span {
    margin: 0 20px;
  }
}
